import React, { Fragment } from "react";

import "../styles/home.css";
import { Container, Row, Col } from "react-bootstrap";
import HadokenParticles from "../components/HadokenParticles";

function Home() {
  return (
    <Fragment>
      <HadokenParticles />
      <Container className="hadokenTitle blueBackground" fluid>
        <Row noGutters>
          <Col xs={2} />
          <Col>
            <div className="hadokenHeading">hadoken</div>
            <br />
            <div className="heading2 whiteText hadokenTagline">
              We help to ensure the <p className="yellowText">safety</p> &amp;{" "}
              <p className="blueText">success</p> of your business
            </div>
            <br />
            <br />
            <div className="bodyCopy1 whiteText hadokenCaption">
              Established in 2021, we’re an independent team of legal experts to
              comprehensively resolve legal issues in the interests of all.
            </div>
          </Col>
          <Col xs={2} />
        </Row>
      </Container>
    </Fragment>
  );
}

export default Home;
