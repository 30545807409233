import Particles from "react-particles-js";
import "../styles/hadokenParticles.css";

function HadokenParticles() {
  return (
    <Particles
      className="hadokenParticles"
      params={{
        particles: {
          color: {
            value: ["#3d6dd8", "#eebb72"],
          },
          life: {
            count: 10,
          },
          number: {
            value: 30,
            density: {
              enable: true,
            },
          },
          size: {
            value: {
              min: 5,
              max: 20,
            },
            random: {
              enable: true,
              minimumValue: 3,
            },
            anim: {
              speed: 10,
              size_min: 5,
            },
          },
          line_linked: {
            enable: true,
            consent: true,
          },
          move: {
            random: true,
            speed: 0.5,
            out_mode: "out",
          },
        },
      }}
    />
  );
}

export default HadokenParticles;
