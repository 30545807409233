import React, { Fragment } from "react";
import logo from "./logo.svg";

import "./styles/app.css";
import "./styles/satoshi.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import Home from "./pages/Home";

function App() {
  return (
    <Fragment>
      <Container className="webPage" fluid>
        <Router>
          <Switch>
            <Route component={Home} />
          </Switch>
        </Router>
      </Container>
    </Fragment>
  );
}

export default App;
